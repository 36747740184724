<template>
    <div class="indexPage">
        <div class="page_content">
            <transition name="van-slide-right">
                <keep-alive>
                    <router-view class="menu-child"/>
                </keep-alive>
            </transition>
        </div>
        <div class="menu_bar" :style="{zIndex:1000}">
            <div class="menu_item" :class="{active:index === menuIndex}"
                 @click="menuIndex = index"
                 v-for="(menu, index) in menuData" :key="index">
                <div class="menu_icon">
                    <img v-show="index === menuIndex" :src="require(`../../public/iconImg/icon_${menu.icon}_active.png`)" alt="">
                    <img v-show="index !== menuIndex" :src="require(`../../public/iconImg/icon_${menu.icon}.png`)" alt="">
                </div>
                <div class="menu_title">{{menu.title}}</div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "Index",
        data(){
            return{
                //菜单索引
                menuIndex:0,
                //菜单数据
                menuData:[],
                transitionName:'van-slide-right'
            }
        },
        mounted() {
            this.pageInit();
        },
        activated() {
            // this.menuIndex = 0;
        },
        watch:{
            $route:{
                handler(to){
                    if(to.path === '/'){
                        this.$router.replace({path:'/Task'});
                        this.menuIndex = 0;
                        return false;
                    }
                    switch (to.path) {
                        case '/Task':
                            this.menuIndex = 0;
                            break;
                        case '/Test':
                            this.menuIndex = 1;
                            break;
                        case '/Wrong':
                            this.menuIndex = 2;
                            break;
                        case '/Exam':
                            this.menuIndex = 3;
                            break;
                        case '/Me':
                            this.menuIndex = 4;
                            break;
                    }
                },
                immediate:true
            },
            menuIndex:{
                handler(index,oIndex){
                    // console.log(index);
                    let path;
                    switch (index) {
                        case 0:
                            path = '/Task';
                            break;
                        case 1:
                            path = '/Test';
                            break;
                        case 2:
                            path = '/Wrong';
                            break;
                        case 3:
                            path = '/Exam';
                            break;
                        case 4:
                            path = '/Me';
                            break;
                    }
                    // this.transitionName = index > oIndex ? 'van-slide-left':'van-slide-right';
                    if(path) this.$router.push({path:path});
                },
                immediate:false,
            },
        },
        methods:{
            //页面初始化
            pageInit(){
                //菜单数据
                this.menuData = [
                    {
                        title:'精准作业',
                        icon:'edit'
                    },
                    {
                        title:'在线测评',
                        icon:'edit1'
                    },{
                        title:'错题训练',
                        icon:'wrong'
                    },
                    {
                        title:'考试',
                        icon:'edit1'
                    },
                    {
                        title:'个人中心',
                        icon:'me'
                    },
                ]
            }
        }
    }
</script>

<style lang="scss">
    .indexPage{
        @extend .flex_column;
        .page_content{
            @extend .flex_height_auto;
            position: relative;
            margin-bottom: 50px;
            /*子路由页面*/
            .menu-child{
                @extend .abs_full_screen;
                background: #fff;
                .task_type{
                    box-shadow: 0 4px 8px 1px rgba(0, 0, 0, 0.1);
                }
            }

        }
        .menu_bar{
            background: #fff;
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            @extend .flex_row_center;
            height: 50px;
            box-shadow: -1px -1px 10px 0px rgba(0, 68, 99, 0.1);
            .menu_item{
                @extend .flex_column_center;
                flex: 1;
                height: 100%;
                .menu_icon{
                    img{
                        width: 20px;
                        height: 20px;
                    }
                }
                .menu_title{
                    font-size: 12px;
                    color: rgba(201, 201, 201, 1);

                }
                &.active{
                    .menu_title {
                        color: rgba(1, 159, 232, 1);
                        font-weight: bold;
                    }
                }
            }
        }

        /*兼容ipad*/
        @media only screen
        and (min-device-width : 768px)
        and (max-device-width : 1024px){
            .page_content{
                margin-bottom: 100px;
            }
            .menu_bar{
                height: 100px;
                .menu_item{
                    .menu_icon{
                        img{
                            width: 40px;
                            height: 40px;
                        }
                    }
                    .menu_title{
                        font-size: 24px;
                    }
                }
            }
        }
    }
</style>
